<script>
  export let isOpen = false;

  import { _ } from "svelte-i18n";
  import { createEventDispatcher } from "svelte";
  import MultiSelect from "svelte-multiselect";
  import { language_options } from "$lib/language-options.js";
  import { settings_store, initialSettings } from "$lib/store.js";
  import Slider from "$lib/slider/Slider.svelte";
  import Select from "svelte-select";
  import { get } from "svelte/store";

  const dispatch = createEventDispatcher();
  let disabled = false;
  let old_setting_values;

  $: if (isOpen) {
    //make deep copy of the settings, otherwise it is only a reference and is still updated
    old_setting_values = JSON.parse(JSON.stringify(get(settings_store)));
  }

  function closeModal() {
    isOpen = false;
    dispatch("close");
  }

  function exitNoChange() {
    settings_store.set(old_setting_values);
    isOpen = false;
    dispatch("close");
  }

  function reset() {
    // do not reset the locale, the region and the cookies
    $settings_store.production_period = initialSettings.production_period;
    $settings_store.audio_languages = initialSettings.audio_languages;
    $settings_store.subtitle_languages = initialSettings.subtitle_languages;
  }
</script>

<!-- ---------------------------------------------------------------------- -->

{#if isOpen}
  <div class="md-modal-container">
    <div class="md-content">
      <h1>Filter settings</h1>

      <div class="productionyear setting-block">
        <strong>{$_("production-year")}:</strong>
        {$settings_store.production_period[0]} - {$settings_store.production_period[1]}
        <Slider min={Number("1930")} max={Number("2024")} bind:value={$settings_store.production_period} />
      </div>

      <div class="languages setting-block">
        <div>
          <strong>{$_("audio-languages")}:</strong><span>({$_("none-means-any")})</span>
        </div>
        <MultiSelect options={language_options} {disabled} bind:selected={$settings_store.audio_languages} />
      </div>

      <div class="languages setting-block">
        <div>
          <strong>{$_("subtitle-languages")}:</strong><span>({$_("none-means-any")})</span>
        </div>
        <MultiSelect options={language_options} {disabled} bind:selected={$settings_store.subtitle_languages} />
      </div>

      <div class="region setting-block">
        <div>
          <strong>{$_("region-setting")}:</strong>
        </div>
        <Select
          items={[
            { value: "DE", label: $_("germany") },
            { value: "CH", label: $_("switzerland") },
            { value: "AT", label: $_("austria") }
          ]}
          value={$settings_store.region}
          bind:justValue={$settings_store.region}
          clearable={false}
          --border="white 1px solid"
          --border-radius="4px"
          --border-color="var(--icon-light-blue)"
          --height="2em"
          --item-color="var(--white)"
          --selected-item-color="var(--white)"
          --item-hover-bg="var(--white-30)"
          --list-background="var(--dark-grey)"
          --item-is-active-bg="var(--solid-blue)"
          --background="var(--dark-grey)" />
      </div>

      <div class="cookies setting-block">
        <div>
          <strong>{$_("advertisement_cookies")}:</strong>
          {$_("cookie_consent_ads")}
        </div>
        <label class="switch" for="cookie_ads">
          <input type="checkbox" id="cookie_ads" name="ads cookies" bind:checked={$settings_store.consent_ad_cookies} />
          <span class="slider"></span>
        </label>
      </div>

      <div class="buttons">
        <button title={$_("reset")} class="reset-button" on:click={reset} on:keypress={reset} />
        <div>
          <button title={$_("exit_no_change")} class="exit-button" on:click={exitNoChange} on:keypress={exitNoChange} />
          <button title={$_("save_change")} class="ok-button" on:click={closeModal} on:keypress={closeModal} />
        </div>
      </div>
    </div>
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .md-modal-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: var(--black-80);
  }
  .md-content {
    width: 720px;
    min-width: 300px;
    height: 100%;
    position: relative;
    margin-top: 60px;
    background-color: var(--black);
    border-radius: 30px;
    padding-left: 5%;
    padding-right: 5%;
    color: var(--white-grey);
    font-size: 16px;
    text-align: left;
    overflow: scroll;
  }
  .productionyear {
    margin-top: 2em;
    --thumb-bg: var(--solid-blue);
    --progress-bg: var(--solid-blue);
    --track-bg: var(--solid-grey);
  }
  .languages {
    margin-top: 1em;
    /* see: https://github.com/janosh/svelte-multiselect#--styling */
    --sms-text-color: var(--white);
    --sms-options-bg: var(--dark-grey);
    --sms-active-color: var(--white-50);
    --sms-selected-bg: var(--solid-blue);
    --sms-selected-li-padding: 4pt 8pt;
  }
  .languages div {
    margin-bottom: 1em;
    display: flex;
    justify-content: space-between;
  }
  .region {
    margin-top: 1em;
  }
  .region div {
    margin-bottom: 1em;
  }

  /* for the cookie consent --------------------------------------*/
  .cookies {
    margin-top: 1em;
  }
  /* iOS styled slider-switch */
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    min-width: 60px;
    height: 34px;
    margin-bottom: auto;
    margin-top: 1em;
  }
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--light-grey);
    transition: 0.4s;
    border-radius: 34px;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: var(--white);
    transition: 0.3s;
    border-radius: 50%;
  }
  input:checked + .slider {
    background-color: var(--lightest-blue);
  }
  input:focus + .slider {
    box-shadow: 0 0 1px var(--lightest-blue);
  }
  input:checked + .slider:before {
    transform: translateX(26px);
  }
  input:disabled + .slider {
    background-color: var(--lightest-blue); /* Grey background for disabled slider */
    cursor: not-allowed;
  }
  input:disabled + .slider:before {
    background-color: var(--white-10); /* Optional: Change knob color for disabled state */
  }
  /* end cookie consent --------------------------------------*/

  .setting-block {
    border-radius: 8px;
    padding: 1em;
    background-color: var(--dark-grey);
  }
  .buttons {
    margin-top: 2em;
    margin-bottom: 5em;
    display: flex;
    justify-content: space-between;
  }
  .reset-button {
    background: url(/buttons/btn_reset.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
  }
  .reset-button:hover {
    background: url(/buttons/btn_reset_hover.svg) no-repeat;
  }
  .exit-button {
    background: url(/buttons/btn_close_details.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: left;
    cursor: pointer;
    margin-right: 1em;
  }
  .exit-button:hover {
    background: url(/buttons/btn_close_details_hover.svg) no-repeat;
  }
  .ok-button {
    background: url(/buttons/btn_ok.svg) no-repeat;
    width: 48px;
    height: 48px;
    border: none;
    position: relative;
    float: right;
    cursor: pointer;
  }
  .ok-button:hover {
    background: url(/buttons/btn_ok_hover.svg) no-repeat;
  }
  /* need to enforce font weight on strong and h1 because they get overruled by global.css */
  strong {
    color: var(--lightest-blue);
    font-weight: 400;
  }
  h1 {
    font-weight: 300;
  }

  /* Make the slider thumb bigger:
  Observe that the slider is an inactive third party component (https://github.com/doklab/mowizz-search/issues/186) 
  we are going to adopt the slider code, then we have all freedom.
  
  There is also an option to entirely define the 'slot' ourselves, but using the :global construct is simpler.
  see also: https://github.com/BulatDashiev/svelte-slider
  */
  .productionyear :global(.thumb) {
    width: 24px;
    height: 24px;
  }
</style>
