<script>
  import Select from "svelte-select";
  import { locale } from "svelte-i18n";
  import { register, init } from "svelte-i18n";
  import { defaultLanguage } from "$lib/LocaleConstants.js";
  import { settings_store } from "$lib/store.js";

  init({
    fallbackLocale: defaultLanguage,
    initialLocale: $locale
  });

  register("en", () => import("$lib/lang/en.json"));
  register("de", () => import("$lib/lang/de.json"));
  register("fr", () => import("$lib/lang/fr.json"));
  // register("it", () => import("$lib/lang/it.json"));
  //FIXME: enable when API is ready: https://github.com/doklab/mowizz-search/issues/180

  let items = [
    { value: "en", label: "EN" },
    { value: "de", label: "DE" },
    { value: "fr", label: "FR" }
    // { value: "it", label: "Italiano" },
    //FIXME: enable when API is ready: https://github.com/doklab/mowizz-search/issues/180
  ];

  function sanitizeLocale(chosenLocale) {
    const result = items.find((item) => item.value === chosenLocale);
    return result ? result.value : defaultLanguage;
  }

  let justValue = sanitizeLocale($locale);
  $: {
    $locale = justValue;
    $settings_store.selected_locale = justValue;
  }
</script>

<!-- ---------------------------------------------------------------------- -->

<label for="SelectLanguage">Select preferred language</label>
<div class="selector">
  <Select
    {items}
    value={$locale}
    clearable={false}
    id="SelectLanguage"
    bind:justValue
    --border="none"
    --height="48px"
    --border-radius="8px"
    --list-border-radius="8px"
    --value-container-padding="0"
    --item-color="var(--icon-light-blue)"
    --selected-item-color="var(--light-grey)"
    --item-hover-bg="var(--white-30)"
    --list-background="var(--dark-grey)"
    --item-is-active-bg="var(--solid-blue)"
    --background="var(--dark-grey)"
    --width="62px" />
</div>

<!-- ---------------------------------------------------------------------- -->

<style>
  .selector {
    padding-right: 0.5em;
  }
  .selector :global(div) {
    /* Fix for the font of the visible selected locale when the dropdown is closed. 
       The itme-color does not work for some reason. 
       Maybe we could fix this nicer by a custom 'slot' definition on the svelte-select.
       https://github.com/rob-balfre/svelte-select?tab=readme-ov-file 
    */
    color: var(--icon-light-blue) !important;
    font-weight: 400;
    user-select: none;
    cursor: pointer;
  }
  label {
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
</style>
